<template>
  <div id="SpectrumPC">
    <div>
      <div class="bg" :style="{ height: clientHeigth + 'px' }">
        <div style="padding: 36px 0 11% 43px">
          <img
            src="@/assets/Lumispectrums.png"
            style="width: 108px; height: 25px"
          />
        </div>
        <div id="bag1">
          <div style="display: flex; justify-content: center">
            <div>
              <img
                src="@/assets/iconBg1.png"
                style="width: 28px; height: 580px; margin-top: 10px"
              />
            </div>
            <div style="margin-left: 36px">
              <div class="t1">Let's build from here.</div>
              <div class="t2">
                为每一位老师打造，<br />教与学新形态+可视化课程知识图谱
              </div>
              <div class="t3">你知道吗？</div>
              <div class="t4">
                人类<span style="color: #a1fcfd">近30年</span
                >来，<br />所积累的知识占有史以来积累的知识<br />总量的<span
                  style="color: #a1fcfd"
                  >90%</span
                >
              </div>
              <div class="t5">来源于：联合国教科文组织统计</div>
              <div class="t6">
                预计全球产生的数字化信息的总量在 2025 年达到 175 ZB（Reinsel et
                al。，2018）。<br />这个数字是什么概念呢？<br />如果将这些数字化的信息全部印成书籍并排列整齐，它们的长度将是从地球到冥王星距离的10倍。
              </div>
              <div class="t1" style="font-size: 20px; font-family: SongTi_Bold">
                知识图谱全景图，让教师拥有「上帝视角」
              </div>
            </div>
          </div>
          <div id="bag2">
            <div style="width: 633px; margin: 0 auto">
              <div class="t1">高等教育领域的知识图谱<br />应时而生</div>
              <div class="t2">
                指结构化、可视化的课程知识网络，<br />是课程领域的知识集合，<br />通过知识图谱梳理清楚课程的知识结构和体系
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bgImg">
        <img
          :src="onlineSrc + '/spectrumstudio/official_temperate/loading.gif'"
          class="loading"
        />
      </div>
      <div id="bag3">
        <div style="display: flex; padding-top: 80px; justify-content: center">
          <div>
            <img
              src="@/assets/iconBg2.png"
              style="width: 28px; height: 150px"
            />
          </div>
          <div style="margin-left: 36px">
            <div class="t1">教学干预数据可视</div>
            <div class="t2">
              预计全球产生的数字化信息的总量在 2025 年达到 175 ZB（Reinsel et
              al。，2018）。<br />这个数字是什么概念呢？<br />如果将这些数字化的信息全部印成书籍并排列整齐，它们的长度将是从地球到冥王星距离的10倍。
            </div>
          </div>
        </div>
      </div>
      <img
        :src="
          onlineSrc + '/spectrumstudio/official_temperate/data_visualize.gif'
        "
      />
      <div class="Jump">
        <div>
          <div class="text1">
            世界瞩目，数字化浪潮已来。<br />让技术为教师所用
          </div>
          <div class="text2">一把开启教育数字化转型大门的金钥匙</div>
          <a target="_blank" href="https://web.lumispectrum.com" class="btn">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              前往开启
              <img
                src="@/assets/keyIcon.png"
                style="width: 24px; margin-left: 20px"
              />
            </div>
          </a>
        </div>
      </div>
      <img
        :src="onlineSrc + '/spectrumstudio/official_temperate/web_bottom.png'"
      />
      <div class="text">
        <span @click="link(1)">京 ICP 备 2023010124 号 - 1</span>
        <span @click="link(2)" style="margin-left: 39px">
          京公网安备11010502052392号
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpectrumPC',
  data () {
    return {
      clientHeigth: 0,
      onlineSrc: process.env.VUE_APP_SRC
    }
  },
  created () {
    this.clientHeigth = document.body.clientWidth / 1.2
  },
  methods: {
    link (val) {
      window.open(
        val === 1
          ? 'https://beian.miit.gov.cn/#/Integrated/index'
          : 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052392'
      )
    }
  }
}
</script>

<style lang="less" scoped>
#SpectrumPC {
  width: 100%;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .loading {
    width: 250px;
    height: 250px;
    margin-left: 56%;
  }
  .bgImg {
    background: url("~@/assets/web_static.png") no-repeat center / cover;
  }
  .bg {
    background: url("~@/assets/iconBg4.png") no-repeat center / cover;
  }
  #bag1 {
    margin: 0 auto;
    .t1 {
      font-size: 40px;
      font-family: SongTi_Heavy;
      font-weight: 400;
    }
    .t2 {
      font-size: 14px;
      font-family: Heiti SC-Light, Heiti SC;
      font-weight: 300;
      line-height: 22px;
    }
    .t3 {
      width: 140px;
      height: 42px;
      border-radius: 26px;
      border: 1px solid #a1fcfd;
      font-size: 14px;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-weight: 600;
      color: #a1fcfd;
      text-align: center;
      line-height: 42px;
      margin: 78px 0 20px;
    }
    .t4 {
      font-size: 20px;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-weight: 600;
      line-height: 36px;
    }
    .t5 {
      font-size: 12px;
      font-family: Heiti SC-Light, Heiti SC;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.5);
      margin-top: 11px;
    }
    .t6 {
      font-size: 12px;
      font-family: PingFang HK-Ultralight, PingFang HK;
      font-weight: 200;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
      margin: 52px 0 65px;
    }
  }
  #bag2 {
    .t1 {
      font-size: 18px;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-weight: 600;
      line-height: 30px;
      padding: 95px 0 42px;
      margin-left: 103px;
    }
    .t2 {
      width: 240px;
      font-size: 12px;
      font-family: Heiti SC-Light, Heiti SC;
      font-weight: 300;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.8);
      margin-left: 516px;
    }
  }
  #bag3 {
    .t1 {
      font-size: 20px;
      font-family: SongTi_SemiBold;
      font-weight: 400;
      color: #a1fcfd;
      margin-bottom: 16px;
    }
    .t2 {
      font-size: 12px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .Jump {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 280px;
    font-weight: 400;
    .text1 {
      font-size: 28px;
      font-family: SongTi_SemiBold;
    }
    .text2 {
      margin: 38px 0;
      font-size: 12px;
      font-family: PingFang HK-Regular, PingFang HK;
    }
    .btn {
      text-decoration: none;
      display: inline-block;
      width: 164px;
      height: 50px;
      background: #a1fcfd;
      border-radius: 30px;
      margin: 0 auto;
      line-height: 50px;
      font-size: 14px;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-weight: 600;
      color: #000000;
    }
  }
  .text {
    font-size: 12px;
    font-family: PingFang HK-Regular, PingFang HK;
    font-weight: 400;
    text-align: center;
    padding-bottom: 50px;
  }
}
</style>
