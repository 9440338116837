<template>
  <div id="SpectrumH5">
    <div style="padding: 1.25rem 0 2.63rem 1.25rem">
      <img
        src="@/assets/Lumispectrums.png"
        style="width: 6.75rem; height: 1.56rem"
      />
    </div>
    <div class="radio_flex">
      <div class="radio">
        <div class="ring_W">
          <div class="ring" />
        </div>
        <div class="Line" />
      </div>
      <div class="text">
        <div class="t1">Let's build from here.</div>
        <div class="t2">
          为每一位老师打造，<br />教与学新形态+可视化课程知识图谱
        </div>
      </div>
    </div>
    <div class="radio_flex">
      <div class="radio">
        <div class="ring_W">
          <div class="ring" />
        </div>
        <div class="Line" />
      </div>
      <div class="text">
        <div class="t3">你知道吗？</div>
        <div class="t4">
          人类<span style="color: #a1fcfd">近30年</span
          >来，<br />所积累的知识占有史以来积累的知识<br />总量的<span
            style="color: #a1fcfd"
            >90%</span
          >
        </div>
        <div class="t5">来源于：联合国教科文组织统计</div>
        <div class="t6">
          预计全球产生的数字化信息的总量在 2025 年达到<br />
          175 ZB（Reinsel et al。，2018）。<br />这个数字是什么概念呢？<br />如果将这些数字化的信息全部印成书籍并排列整齐，<br />它们的长度将是从地球到冥王星距离的10倍。
        </div>
      </div>
    </div>
    <div class="radio_flex">
      <div class="radio">
        <div class="ring_W">
          <div class="ring" />
        </div>
        <div class="Line" />
      </div>
      <div class="text" style="transform: translateY(-0.19rem)">
        <div class="t1 t1_1">知识图谱全景图，让教师拥有「上帝视角」</div>
        <div class="t7">高等教育领域的知识图谱应时而生</div>
        <div class="iconBg3">
          <img src="@/assets/iconBg3.png" />
        </div>
        <div class="t8">
          指结构化、可视化的课程知识网络，<br />是课程领域的知识集合，<br />通过知识图谱梳理清楚课程的知识结构和体系
        </div>
        <div id="IMG">
          <img src="@/assets/bgLoading.png" class="bgLoading" />
          <img
            :src="onlineSrc + '/spectrumstudio/official_temperate/loading.gif'"
            class="loading"
          />
        </div>
        <div class="btnImg">
          <img src="@/assets/btnImg.png" />
        </div>
      </div>
    </div>
    <div class="radio_flex">
      <div class="radio">
        <div class="ring_W">
          <div class="ring" />
        </div>
      </div>
      <div class="text" style="transform: translateY(-0.19rem)">
        <div class="t9">教学干预数据可视</div>
        <div class="t10">
          预计全球产生的数字化信息的总量在 2025 <br />年达到 175 ZB（Reinsel et
          al。，2018）<br />这个数字是什么概念呢？<br />如果将这些数字化的信息全部印成书籍并排<br />列整齐，它们的长度将是从地球到冥王星距<br />离的10倍。
        </div>
      </div>
    </div>
    <img
      :src="onlineSrc + '/spectrumstudio/official_temperate/data_visualize.gif'"
      style="width: 100%"
    />
    <div class="Jump">
      <div>
        <div class="text1">
          世界瞩目，数字化浪潮已来。<br />让技术为教师所用
        </div>
        <div class="text2">一把开启教育数字化转型大门的金钥匙</div>
        <a target="_blank" href="https://web.lumispectrum.com" class="btn">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            前往开启
            <img
              src="@/assets/keyIcon.png"
              style="width: 1.5rem; margin-left: 1.25rem"
            />
          </div>
        </a>
      </div>
    </div>
    <img src="@/assets/web_bottom.png" style="width: 100%" />
    <div class="filings" @click="link(1)">京 ICP 备 2023010124 号 - 1</div>
    <div class="filings" style="padding: 0 0 1.56rem 0" @click="link(2)">
      京公网安备11010502052392号
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpectrumH5',
  data () {
    return {
      onlineSrc: process.env.VUE_APP_SRC
    }
  },
  methods: {
    link (val) {
      window.open(
        val === 1
          ? 'https://beian.miit.gov.cn/#/Integrated/index'
          : 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052392'
      )
    }
  }
}
</script>

<style lang="less" scoped>
#SpectrumH5 {
  width: 100%;
  margin: 0 auto;
  background: url("~@/assets/Radio.png") no-repeat;
  background-size: 100%;
  .radio_flex {
    display: flex;
    justify-content: center;
    margin-top: -0.13rem;
    .radio {
      z-index: 100;
      margin-right: 0.75rem;
      .ring_W {
        width: 1rem;
        height: 1rem;
        border: 0.06rem solid #ffffff;
        border-radius: 50%;
        .ring {
          width: 0.5rem;
          height: 0.5rem;
          background-color: #ffffff;
          border-radius: 50%;
          border: 0.25rem solid #000000;
        }
      }
      .Line {
        width: 0.06rem;
        height: calc(100% - 1.25rem);
        background: #ffffff;
        margin: 0 auto;
      }
    }
    .text {
      width: 17.88rem;
      transform: translateY(-0.5rem);
      .iconBg3 img {
        width: 23.38rem;
        height: 10.44rem;
        margin-left: -3.88rem;
      }
      .btnImg img {
        width: 17.94rem;
        height: 5.13rem;
        margin-bottom: 5.19rem;
        margin-left: -1.56rem;
      }
      #IMG {
        display: flex;
        .bgLoading {
          width: 13.38rem;
          height: 13.19rem;
          margin-left: -3.88rem;
          z-index: 2;
        }
        .loading {
          width: 14.63rem;
          height: 14.63rem;
          margin-left: -4.38rem;
          z-index: 1;
        }
      }
      .t1 {
        font-size: 1.38rem;
        font-family: SongTi_Heavy;
        font-weight: 800;
      }
      .t1_1 {
        white-space: nowrap;
        font-size: 0.94rem;
        font-family: SongTi_Bold;
        font-weight: bold;
      }
      .t2 {
        font-size: 0.88rem;
        font-family: Heiti SC-Light, Heiti SC;
        font-weight: 300;
        color: #8e8e8e;
        line-height: 1.38rem;
        margin: 0.63rem 0 2.75rem;
      }
      .t3 {
        width: 8.75rem;
        height: 2.63rem;
        border-radius: 1.63rem;
        border: 0.06rem solid #a1fcfd;
        font-size: 0.88rem;
        font-family: PingFang HK-Semibold, PingFang HK;
        font-weight: 600;
        color: #a1fcfd;
        text-align: center;
        line-height: 2.63rem;
        transform: translateY(-0.31rem);
      }
      .t4 {
        font-size: 1rem;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        line-height: 1.63rem;
        margin: 1.13rem 0 0.81rem;
      }
      .t5 {
        font-size: 0.75rem;
        font-family: Heiti SC-Light, Heiti SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.5);
      }
      .t6 {
        font-size: 0.75rem;
        font-family: PingFang HK-Ultralight, PingFang HK;
        font-weight: 200;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 0.5);
        margin: 1.69rem 0 4.19rem;
      }
      .t7 {
        font-size: 0.88rem;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        line-height: 1.88rem;
        color: rgba(255, 255, 255, 0.9);
        margin-top: 2.63rem;
      }
      .t8 {
        font-size: 0.75rem;
        font-family: Heiti SC-Light, Heiti SC;
        font-weight: 300;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 0.8);
        margin-top: -0.5rem;
      }
      .t9 {
        font-size: 0.94rem;
        font-family: SongTi_SemiBold;
        font-weight: 600;
        color: #a1fcfd;
      }
      .t10 {
        font-size: 0.88rem;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        line-height: 1.63rem;
        margin: 0.69rem 0 1.69rem;
      }
    }
  }
  .Jump {
    text-align: center;
    .text1 {
      font-size: 1.25rem;
      font-family: SongTi_SemiBold;
    }
    .text2 {
      margin: 1.63rem 0;
      font-size: 0.75rem;
      font-family: PingFang HK-Regular, PingFang HK;
    }
    .btn {
      text-decoration: none;
      display: inline-block;
      width: 10.25rem;
      height: 2.88rem;
      background: #a1fcfd;
      border-radius: 1.88rem;
      margin: 0 auto 1.56rem;
      line-height: 2.88rem;
      font-size: 0.88rem;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-weight: 600;
      color: #333;
    }
  }
  .filings {
    font-size: 0.63rem;
    font-family: PingFang HK-Regular, PingFang HK;
    font-weight: 400;
    color: #888888;
    text-align: center;
    padding: 3.56rem 0 0.69rem;
  }
}
</style>
