<template>
  <div id="Spectrum">
    <SpectrumPC v-if="!_isMobile()"/>
    <SpectrumH5 v-if="_isMobile()"/>
  </div>
</template>

<script>
import SpectrumPC from './SpectrumPC.vue'
import SpectrumH5 from './SpectrumH5.vue'
export default {
  name: 'Spectrum',
  components: {
    SpectrumPC,
    SpectrumH5
  }
}
</script>

<style lang="less" scoped>
</style>
